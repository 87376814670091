@import url("./ui/foundations/colors.css");
@import url("./ui/foundations//elevation.css");
@import url("./ui/foundations/numbers.css");

* {
    box-sizing: border-box;
}

.local-viewer .agora_video_player {
    object-fit: cover !important;
    position: fixed !important;
    top: 0 !important;
    left: 0 !important;
    width: 100% !important;
    z-index: 0 !important;
}