:root {
  --spacing-x-small: 4px;
  --spacing-small: 8px;
  --spacing-standard: 16px;
  --spacing-large: 24px;
  --spacing-x-large: 32px;
  --spacing-xx-large: 40px;
  --spacing-xxx-large: 48px;
  --radius-small: 4px;
  --radius-standard: 8px;
  --radius-large: 16px;
  --radius-x-large: 24px;
  --icons-xs: 16px;
  --icons-small: 20px;
  --icons-standard: 24px;
  --icons-large: 32px;
  --height-xs: 32px;
  --height-small: 40px;
  --height-medium: 48px;
  --height-large: 56px;
}
