/* .Base Colors - Mode 1 */
:root {
    --purple-50: rgba(247, 245, 252, 1);
    --purple-100: rgba(215, 207, 239, 1);
    --purple-200: rgba(184, 169, 226, 1);
    --purple-300: rgba(153, 131, 214, 1);
    --purple-400: rgba(121, 93, 201, 1);
    --purple-500: rgba(90, 55, 188, 1);
    --purple-600: rgba(79, 48, 165, 1);
    --purple-700: rgba(68, 41, 141, 1);
    --purple-800: rgba(56, 34, 118, 1);
    --orange-300: rgba(255, 162, 116, 1);
    --purple-900: rgba(45, 28, 94, 1);
    --orange-500: rgba(255, 98, 31, 1);
    --orange-600: rgba(236, 75, 3, 1);
    --red-500: rgba(251, 55, 70, 1);
    --orange-700: rgba(217, 52, 0, 1);
    --red-600: rgba(220, 48, 61, 1);
    --green-600: rgba(39, 168, 121, 1);
    --red-700: rgba(188, 41, 53, 1);
    --green-700: rgba(33, 144, 104, 1);
    --green-800: rgba(28, 120, 86, 1);
    --blue-600: rgba(45, 113, 223, 1);
    --blue-800: rgba(32, 81, 159, 1);
    --gray-50: rgba(244, 245, 251, 1);
    --yellow-500: rgba(255, 197, 66, 1);
    --gray-100: rgba(236, 238, 248, 1);
    --gray-200: rgba(225, 227, 244, 1);
    --gray-300: rgba(212, 215, 232, 1);
    --gray-400: rgba(184, 187, 209, 1);
    --gray-500: rgba(160, 163, 189, 1);
    --gray-700: rgba(117, 119, 146, 1);
    --gray-800: rgba(86, 88, 114, 1);
    --gray-900: rgba(55, 57, 80, 1);
    --white: rgba(255, 255, 255, 1);
    --black: rgba(39, 46, 53, 1);
    --absolute-black: #000;
    --none: rgba(255, 255, 255, 0.009999999776482582);
    --yellow-700: rgba(191, 148, 50, 1);
    --transparent-light-50: rgba(255, 255, 255, 0.5);
    --transparent-light-75: rgba(255, 255, 255, 0.75);
    --transparent-dark-25: rgba(0, 0, 0, 0.25);
    --transparent-dark-40: rgba(0, 0, 0, 0.4000000059604645);
    --transparent-dark-60: rgba(0, 0, 0, 0.6000000238418579);
}

/* Color Tokens - Light */
:root {
    --light-text-default: var(--black);
    --light-text-subtle: var(--gray-800);
    --light-text-placeholder: var(--gray-500);
    --light-text-success: var(--green-800);
    --light-text-alert: var(--red-600);
    --light-text-primary: var(--purple-500);
    --light-text-accent: var(--orange-600);
    --light-text-link: var(--blue-600);
    --light-text-on-image: var(--white);
    --light-text-warning: var(--yellow-700);
    --light-icon-default: var(--gray-800);
    --light-item-hover: var(--purple-100);
    --light-item-active: var(--purple-200);
    --light-background-default: var(--white);
    --light-background-disabled: var(--gray-50);
    --light-background-screen: var(--gray-200);
    --light-background-chip: var(--gray-200);
    --light-background-selected: var(--purple-50);
    --light-background-overlay: var(--transparent-dark-25);
    --light-background-image-overlay: var(--transparent-dark-40);
    --light-background-success: var(--green-700);
    --light-background-alert: var(--red-600);
    --light-background-accent: var(--orange-600);
    --light-background-warning: var(--yellow-500);
    --light-background-info: var(--gray-300);
    --light-background-primary: var(--purple-500);
    --light-background-opaque: var(--transparent-light-75);
    --light-icon-subtle: var(--gray-400);
    --light-icon-heavy: var(--black);
    --light-item-subtle-hover: var(--gray-50);
    --light-border-default: var(--gray-300);
    --light-border-dark: var(--gray-500);
    --light-border-subtle: var(--gray-100);
    --light-border-selected: var(--purple-500);
    --light-border-alert: var(--red-600);
    --light-border-accent: var(--orange-500);
    --light-border-white: var(--white);
    --light-icon-alert: var(--red-600);
    --light-icon-success: var(--green-800);
    --light-icon-primary: var(--purple-500);
    --light-icon-accent: var(--orange-600);
    --light-icon-white: var(--white);
    --light-icon-blue: var(--blue-600);
    --light-icon-warning: var(--yellow-700);
}

/* Color Tokens - Dark */
:root {
    --dark-text-default: var(--white);
    --dark-text-subtle: var(--gray-300);
    --dark-text-placeholder: var(--gray-500);
    --dark-text-success: var(--green-700);
    --dark-text-alert: var(--red-600);
    --dark-text-primary: var(--white);
    --dark-text-accent: var(--orange-500);
    --dark-text-link: var(--blue-600);
    --dark-text-on-image: var(--white);
    --dark-text-warning: var(--yellow-500);
    --dark-icon-default: var(--gray-300);
    --dark-background-default: var(--gray-900);
    --dark-item-hover: var(--purple-100);
    --dark-background-disabled: var(--gray-800);
    --dark-item-active: var(--purple-300);
    --dark-background-screen: var(--black);
    --dark-background-chip: var(--gray-800);
    --dark-background-selected: var(--purple-100);
    --dark-background-overlay: var(--transparent-light-50%);
    --dark-icon-subtle: var(--gray-800);
    --dark-background-image-overlay: var(--transparent-light-50%);
    --dark-icon-heavy: var(--white);
    --dark-item-subtle-hover: var(--gray-800);
    --dark-border-default: var(--gray-100);
    --dark-border-dark: var(--gray-100);
    --dark-border-subtle: var(--gray-300);
    --dark-border-selected: var(--white);
    --dark-border-alert: var(--red-600);
    --dark-border-accent: var(--orange-500);
    --dark-border-white: var(--white);
    --dark-icon-alert: var(--red-600);
    --dark-icon-success: var(--green-600);
    --dark-icon-primary: var(--purple-500);
    --dark-icon-accent: var(--orange-500);
    --dark-background-success: var(--green-700);
    --dark-background-alert: var(--red-600);
    --dark-background-accent: var(--orange-500);
    --dark-background-warning: var(--yellow-500);
    --dark-background-info: var(--gray-300);
    --dark-background-primary: var(--white);
    --dark-background-opaque: var(--transparent-light-50%);
    --dark-icon-white: var(--white);
    --dark-icon-blue: var(--blue-600);
    --dark-icon-warning: var(--yellow-500);
}

/* Color Tokens - Button */
:root {
    --button-text-default: var(--text-&-icon);
    --button-text-subtle: var(--gray-300);
    --button-text-placeholder: var(--gray-500);
    --button-text-success: var(--green-800);
    --button-text-alert: var(--red-600);
    --button-text-primary: var(--purple-500);
    --button-text-accent: var(--orange-600);
    --button-text-link: var(--blue-600);
    --button-text-on-image: var(--white);
    --button-text-warning: var(--yellow-700);
    --button-icon-default: var(--text-&-icon);
    --button-background-default: var(--default-background);
    --button-item-hover: var(--hover-background);
    --button-background-disabled: var(--gray-50);
    --button-item-active: var(--active-background);
    --button-background-screen: var(--black);
    --button-background-chip: var(--gray-800);
    --button-background-selected: var(--purple-50);
    --button-background-overlay: var(--transparent-dark-25%);
    --button-icon-subtle: var(--gray-400);
    --button-background-image-overlay: var(--transparent-dark-40%);
    --button-icon-heavy: var(--black);
    --button-item-subtle-hover: var(--gray-50);
    --button-border-default: var(--default-border);
    --button-border-dark: var(--gray-500);
    --button-border-subtle: var(--gray-100);
    --button-border-selected: var(--purple-500);
    --button-border-alert: var(--red-600);
    --button-border-accent: var(--orange-500);
    --button-border-white: var(--white);
    --button-icon-alert: var(--text-alert);
    --button-icon-success: var(--text-success);
    --button-icon-primary: var(--text-primary);
    --button-icon-accent: var(--text-accent);
    --button-background-success: var(--green-700);
    --button-background-alert: var(--red-600);
    --button-background-accent: var(--orange-600);
    --button-background-warning: var(--yellow-500);
    --button-background-info: var(--gray-300);
    --button-background-primary: var(--purple-500);
    --button-background-opaque: var(--transparent-light-75%);
    --button-icon-white: var(--white);
    --button-icon-blue: var(--text-link);
    --button-icon-warning: var(--text-warning);
}

/* .Button Tokens - Primary */
:root {
    --contained-button-default-background: var(--purple-500);
    --contained-button-default-border: var(--none);
    --contained-button-text-icon: var(--white);
    --contained-button-large-minwidth: 200px;
    --contained-button-small-minwidth: 100px;
    --contained-button-focus-outline: var(--purple-100);
    --contained-button-active-background: var(--purple-800);
    --contained-button-focus-background: var(--purple-500);
    --contained-button-hover-background: var(--purple-600);
    --contained-button-progress-background: var(--purple-400);
    --contained-button-progress-bar: var(--purple-500);
}

/* .Button Tokens - Secondary */
:root {
    --outline-button-default-background: var(--white);
    --outline-button-default-border: var(--purple-500);
    --outline-button-text-icon: var(--purple-500);
    --outline-button-large-minwidth: 200px;
    --outline-button-small-minwidth: 100px;
    --outline-button-focus-outline: var(--purple-100);
    --outline-button-active-background: var(--purple-200);
    --outline-button-focus-background: var(--white);
    --outline-button-hover-background: var(--purple-100);
    --outline-button-progress-background: var(--white);
    --outline-button-progress-bar: var(--purple-100);
}

/* .Button Tokens - Ghost */
:root {
    --ghost-button-default-background: var(--none);
    --ghost-button-default-border: var(--gray-500);
    --ghost-button-text-icon: var(--gray-800);
    --ghost-button-large-minwidth: 200px;
    --ghost-button-small-minwidth: 100px;
    --ghost-button-focus-outline: var(--gray-200);
    --ghost-button-active-background: var(--gray-300);
    --ghost-button-focus-background: var(--none);
    --ghost-button-hover-background: var(--gray-50);
    --ghost-button-progress-background: var(--none);
    --ghost-button-progress-bar: var(--gray-100);
}

/* .Button Tokens - Text */
:root {
    --text-button-default-background: var(--none);
    --text-button-default-border: var(--none);
    --text-button-text-icon: var(--purple-500);
    --text-button-large-minwidth: 0px;
    --text-button-small-minwidth: 0px;
    --text-button-focus-outline: var(--none);
    --text-button-active-background: var(--gray-300);
    --text-button-focus-background: var(--gray-100);
    --text-button-hover-background: var(--gray-50);
    --text-button-progress-background: var(--none);
    --text-button-progress-bar: var(--gray-100);
}
