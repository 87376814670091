:root {
  --elevation-text: 0px 0px 2px 0px #000;
  --elevation-snackbar: 0px 4px 8px 3px rgba(0, 0, 0, 0.15), 0px 1px 3px 0px rgba(0, 0, 0, 0.3);
  --elevation-basic: 0px 2px 8px 0px rgba(141, 143, 168, 0.22);
  --elevation-action: 0px 3px 6px 0px var(--transparent-dark-25);
  --elevation-inner: inset 0px 3px 8px 0px rgba(141, 143, 168, 0.2);

  --elevation-focus-button-contained: 0px 0px 0px 3px var(--contained-button-focus-outline);
  --elevation-focus-button-outlined: 0px 0px 0px 3px var(--outline-button-focus-outline);
  --elevation-focus-button-ghost: 0px 0px 0px 3px var(--ghost-button-focus-outline);
  --elevation-focus-button-text: 0px 0px 0px 3px var(--text-button-focus-outline);
}
